import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "../components/common/page"
import Header from "../components/header"
import Footer from "../components/footer"
import { useTranslation } from "react-i18next"
const CookiesPage = (props: PageProps) => {
  const {t} = useTranslation();

  return (
    <Page title="Cookies">
      <Header/>
      <div className="w-full overflow-x-hidden">
        <div className="block lg:flex lg:flex-row h-screen justify-center">
          <div className="w-full lg:w-1/2 px-5 lg:px-1">
            <p className="mt-3">
              {t('we_use_cookies')}
            </p>
            <p className="text-secondary text-2xl lg:text-4xl leading-10 mt-4">
            {t('what_are_cookies')}
            </p>
            <p className="mt-3 text-justify">
            {t('what_are_cookies_explanations')}

            </p>
            <p className="text-secondary  text-2xl lg:text-4xl leading-10 mt-5">
            {t('where_we_use_cookies')}
            </p>
            <p className="text-secondary text-xl lg:text-2xl leading-10 mt-4">
            {t('necessary_cookies')}
            </p>
            <p className="mt-2 text-justify">
            {t('necessary_cookies_text')}

            </p>
            <p className="text-secondary  text-2xl lg:text-4xl leading-10 mt-5">
            {t('more_info_for_cookies')}

            </p>
            <p className="mt-3 text-justify"></p>
            {t('more_info_for_cookies_text')}
             <a href="http://cookiepedia.co.uk/all-about-cookies" className="text-secondary" download>http://cookiepedia.co.uk/all-about-cookies</a> 
             {" "} {t('and')} {" "}
              <a href="http://www.allaboutcookies.org/" className="text-secondary">http://www.allaboutcookies.org/</a>.
          </div>
        </div>
      </div>
      <Footer/>
    </Page>
  )
}

export default CookiesPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies", "common"]}, language: {eq: $language}})  {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;